<template>
  <div class="entrance main-cnt">
    <div class="title">入口列表</div>
    <div class="content">
      <common-table
        ref="entranceTable"
        tableHeight="calc(100vh - 325px)"
        :ischeck="false"
        :ispaging="true"
        :apiName="BasicApi.entranceList"
        :filters="filters"
        :columns="tableColumns"
        @statusChange="statusChange"
        @edit="entranceEdit"
        @showAlbum="showGate"
      >
        <template #operate>
          <el-button
            type="primary"
            round
            @click="showAddDialog"
            v-if="authData.indexOf('r_XLYcy9D30xT6kOWGFSjH5MEvKQCg') != -1"
          >
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增入口</el-button
          >
        </template>
      </common-table>
    </div>

    <!-- 新增/编辑 入口弹框 -->
    <w-dialog
      ref="addDialog"
      class="add-dialog"
      :title="isEdit ? '编辑入口' : '新增入口'"
      width="50%"
      btnWidth="140px"
      top="20vh"
      :confirmText="isEdit ? '确认编辑' : '确认新增'"
      @wConfirm="confirm"
    >
      <el-form
        class="add-form"
        ref="addForm"
        :model="ruleForm"
        :rules="rules"
        labelPosition="top"
      >
        <el-form-item label="所属项目" prop="village">
          <el-select
            v-model="ruleForm.village"
            clearable
            placeholder="请选择所属项目"
            @change="projectChange"
          >
            <el-option
              :label="item.p_name"
              :value="item.p_id"
              v-for="item in projectOptions"
              :key="item.p_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属景区" prop="scenicArea">
          <el-select
            v-model="ruleForm.scenicArea"
            clearable
            placeholder="请选择所属景区"
            @change="scenicChange"
          >
            <el-option
              :label="item.s_name"
              :value="item.s_id"
              v-for="item in scenicOptions"
              :key="item.s_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属景点" prop="scenicSpot">
          <el-select
            v-model="ruleForm.scenicSpot"
            clearable
            placeholder="请选择所属景点"
          >
            <el-option
              :label="item.sp_name"
              :value="item.sp_id"
              v-for="item in spotOptions"
              :key="item.sp_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入口名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入入口名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="通行时间" prop="transitTime">
          <el-time-picker
            v-model="ruleForm.transitTime"
            is-range
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          >
          </el-time-picker>
        </el-form-item>
      </el-form>
    </w-dialog>

    <!-- 道闸列表 弹框 -->
    <w-dialog
      ref="gateDialog"
      class="gate-dialog"
      title="道闸列表"
      width="60%"
      btnWidth="140px"
      top="10vh"
      :hideFooter="true"
    >
      <common-table
        ref="gateTable"
        :ischeck="false"
        :ispaging="false"
        :apiName="BasicApi.gateList"
        :columns="gateTableColumns"
        :extraParame="{ dg_seid: currentGateRow.se_id }"
      >
      </common-table>
    </w-dialog>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, computed, watch, nextTick } from "vue";
import { ElMessage } from "element-plus";
import { BasicApi } from "@/plugins/api.js";
import * as dayjs from "dayjs";
import { useStore } from "vuex";

const store = useStore();
const menuTokens = computed(() => store.state.menuToken.menuTokens);
const authData = ref([]);
watch(
  () => menuTokens.value,
  (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
/** 筛选条件列表 */
const filters = ref([
  {
    filterType: "select",
    name: "p_id",
    value: "",
    placeholder: "请选择项目",
    options: [],
    val: "p_id",
    lab: "p_name",
  },
  {
    filterType: "search",
    name: "keywords",
    value: "",
    placeholder: "输入景区名称搜索",
  },
]);
/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "se_id",
    label: "入口ID",
    minWidth: 70,
    color: "--text-color",
  },
  {
    prop: "p_name",
    label: "所属项目",
    color: "--text-third-color",
  },
  {
    prop: "relation_name",
    label: "所属景区",
    minWidth: 140,
    color: "--text-color",
    showTooltip: true,
  },
  {
    prop: "se_name",
    label: "入口名称",
    minWidth: 120,
    color: "--text-color",
    showTooltip: true,
  },
  {
    prop: "se_access_stime",
    label: "通行开始时间",
    minWidth: 120,
    color: "--text-color",
  },
  {
    prop: "se_access_etime",
    label: "通行结束时间",
    minWidth: 120,
    color: "--text-color",
  },
  {
    type: "block",
    prop: "gate_count",
    label: "关联道闸",
    token: "r_v0C4cNam1phZP8tlOeAsV5znjGWM",
  },
  {
    type: "switch",
    prop: "se_status",
    label: "入口状态",
    token: "c_6nsjoSHFRdc59UYwXIJK2gvz0ZAE",
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 100,
    bottons: [
      {
        name: "编辑",
        action: "edit",
        token: "c_vHZrXyFn2bkdQmptGhDiO1AwY6UB",
        className: "theme-font-btn",
      },
    ],
  },
]);
/** 入口表格对象 */
const entranceTable = ref(null);
const gateTable = ref(null);
/** 当前操作行 */
const currentRow = ref(null);
const currentGateRow = ref(null);
/** 点击 关联道闸 */
const showGate = (row) => {
  gateDialog.value.show();
  currentGateRow.value = row;
  nextTick(() => {
    // 获取包含景点列表数据
    gateTable.value.tableLoad();
  });
};
/** 状态改变处理 */
const statusChange = (row) => {
  let data = {
    se_id: row.se_id,
    se_status: row.se_status == 1 ? 2 : 1,
  };
  BasicApi.setEntranceStatus(data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("入口状态修改成功！");
      // 重新获取套餐列表数据
      entranceTable.value.tableLoad();
    } else {
      let msg = res.Message ? res.Message : "入口状态修改失败！";
      ElMessage.error(msg);
    }
  });
};
/** 表格编辑 */
const entranceEdit = (row) => {
  const parmas = {
    se_id: row.se_pid,
  };
  BasicApi.entranceDetail(parmas).then((res) => {
    if (res.Code === 200) {
      isEdit.value = true;
      currentRow.value = res.Data;
      let st = dayjs(
        dayjs().format("YYYY-MM-DD") + " " + res.Data.se_access_stime
      );
      let et = dayjs(
        dayjs().format("YYYY-MM-DD") + " " + res.Data.se_access_etime
      );
      ruleForm.village = res.Data.se_pid; // 项目
      ruleForm.scenicArea = res.Data.se_sid; // 所属景区
      ruleForm.scenicSpot = res.Data.se_spid; // 所属景点
      ruleForm.name = res.Data.se_name; // 入口名称
      ruleForm.transitTime = [st, et]; // 通行时间
      scenicOptions.value = [];
      spotOptions.value = [];
      addDialog.value.show();
      if (res.Data.se_pid) {
        // 获取景区数据
        getScenicByProject({ pid: res.Data.se_pid });
      }

      if (res.Data.se_sid) {
        // 获取景点数据
        getSpotByScenic({ sp_sid: res.Data.se_sid });
      }
    } else {
      let msg = res.Message ? res.Message : `入口详情获取失败！`;
      ElMessage.error(msg);
    }
  });
};

/** 新增/编辑弹框 */
const addDialog = ref(null);
/** 当前是否是编辑操作 */
const isEdit = ref(false);
/** 表单对象 */
const addForm = ref(null);
/** 表单数据对象 */
const ruleForm = reactive({
  village: "", // 项目
  scenicArea: "", // 所属景区
  scenicSpot: "", // 所属景点
  name: "", // 入口名称
  transitTime: [], // 通行时间
});
/** 表单规则对象 */
const rules = reactive({
  village: [
    {
      required: true,
      message: "请选择项目",
      trigger: "change",
    },
  ],
  scenicArea: [
    {
      required: true,
      message: "请选择所属景区",
      trigger: "change",
    },
  ],
  name: [
    {
      required: true,
      message: "请输入入口名称",
      trigger: "blur",
    },
  ],
  transitTime: [
    {
      required: true,
      message: "请选择通行时间",
      trigger: "change",
    },
  ],
});
/** 项目选择更改 */
const projectChange = (value) => {
  ruleForm.scenicArea = "";
  scenicOptions.value = [];
  ruleForm.scenicSpot = "";
  spotOptions.value = [];
  if (value) {
    // 获取景区列表数据
    getScenicByProject({ pid: value });
  }
};
/** 景区选择更改 */
const scenicChange = (value) => {
  ruleForm.scenicSpot = "";
  spotOptions.value = [];
  if (value) {
    // 获取景点列表数据
    getSpotByScenic({ sp_sid: value });
  }
};
/** 弹出 新增入口 弹框 */
const showAddDialog = () => {
  isEdit.value = false;
  ruleForm.village = ""; // 项目
  ruleForm.scenicArea = ""; // 所属景区
  ruleForm.scenicSpot = ""; // 所属景点
  ruleForm.name = ""; // 入口名称
  ruleForm.transitTime = []; // 通行时间
  scenicOptions.value = [];
  spotOptions.value = [];
  addDialog.value.show();
};
/** 新增/编辑确认 */
const confirm = () => {
  addForm.value.validate((valid) => {
    if (valid) {
      addDialog.value.isLoading = true;
      let data = {
        se_name: ruleForm.name,
        se_access_stime: dayjs(ruleForm.transitTime[0]).format("HH:mm:ss"),
        se_access_etime: dayjs(ruleForm.transitTime[1]).format("HH:mm:ss"),
      };
      let url = "entranceAdd";
      if (isEdit.value) {
        url = "entranceEdit";
        data.se_id = currentRow.value.se_id;
      }
      data.se_pid = ruleForm.village;
      data.se_sid = ruleForm.scenicArea;
      data.se_spid = ruleForm.scenicSpot;
      if (data.se_spid) {
        data.se_sid = "";
      }
      BasicApi[url](data).then((res) => {
        addDialog.value.isLoading = false;
        let text = isEdit.value ? "编辑" : "新增";
        if (res.Code === 200) {
          ElMessage.success(`入口${text}成功！`);
          addDialog.value.close();
          // 重新获取入口数据
          entranceTable.value.tableLoad();
        } else {
          let msg = res.Message ? res.Message : `入口${text}失败！`;
          ElMessage.error(msg);
        }
      });
    }
  });
};

/** 道闸列表 弹框 */
const gateDialog = ref(null);
/** 道闸表格配置 */
const gateTableColumns = ref([
  {
    prop: "dg_id",
    label: "序号",
    minWidth: 60,
    color: "--text-third-color",
  },
  {
    prop: "entrance",
    prop2: "se_name",
    label: "所属入口/车场",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    prop: "dg_name",
    label: "闸机名称",
    minWidth: 120,
    color: "--text-color",
  },
  {
    prop: "dg_online",
    label: "在线状态",
    minWidth: 120,
    color: "--text-color",
    type: "option",
    metaData: ["", "待接入", "在线", "离线"],
    colorData: ["", "#FF3333", "#67C23A", "#cccccc"],
  },
  {
    prop: "dg_sn",
    label: "控制机序号",
    minWidth: 120,
    color: "--text-color",
  },
  {
    prop: "dg_ip",
    label: "设备IP地址",
    minWidth: 120,
    color: "--text-color",
  },
]);

/** 项目列表数据 */
const projectOptions = ref([]);
/** 获取项目数据 */
const getProjectData = () => {
  BasicApi.projectSelect().then((res) => {
    if (res.Code === 200) {
      projectOptions.value = res.Data ? res.Data : [];
      filters.value[0].options = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取项目数据失败！";
      ElMessage.error(msg);
    }
  });
};

/** 景区列表数据 */
const scenicOptions = ref([]);
/** 获取项目下景区数据 */
const getScenicByProject = (data) => {
  BasicApi.getScenicByProject(data).then((res) => {
    if (res.Code === 200) {
      scenicOptions.value = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取景区数据失败！";
      ElMessage.error(msg);
    }
  });
};

/** 景点列表数据 */
const spotOptions = ref([]);
/** 获取景区下景点数据 */
const getSpotByScenic = (data) => {
  BasicApi.getSpotByScenic(data).then((res) => {
    if (res.Code === 200) {
      spotOptions.value = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取景点数据失败！";
      ElMessage.error(msg);
    }
  });
};

onMounted(() => {
  // 获取项目数据
  getProjectData();
  // 获取闸机表格数据
  entranceTable.value.tableLoad();
});
</script>

<style lang="scss">
.entrance {
  font-family: "Source Han Sans CN";
  .content {
    padding: 20px;
  }
  .add-dialog {
    .el-dialog {
      min-width: 840px;
      .el-dialog__body {
        padding: 5px 15px 80px;
        .add-form {
          display: flex;
          flex-wrap: wrap;
          .el-form-item {
            width: 33.3%;
            padding: 25px 15px 0;
            margin-bottom: 0;
            .el-form-item__content {
              .el-select {
                width: 100%;
                .el-input {
                  width: 100%;
                }
              }
              .el-date-editor {
                width: 100%;
                .el-range-input {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
